:root {
    --text-multiplier: 1;
    --width-multiplier: 1;
}
@media (min-width: 1981px) {
    :root {
        --text-multiplier: 1;
    }
}
@media (min-width: 3840px) {
    :root {
        --text-multiplier: 1;
        --width-multiplier: 1;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.cursor-pointer {
    cursor: pointer;
}

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.flex-direction-column {
    flex-direction: column;
}

.p-0 {
    padding: 0;
}

.p-05 {
    padding: 0.5em;
}

.p-1 {
    padding: 1em;
}

.p-2 {
    padding: 2em;
}

.pr-1 {
    padding-right: 1em;
}

.pr-05 {
    padding-right: 0.5em;
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 1em;
}

.mt-2 {
    margin-top: 2em;
}

.mt-1 {
    margin-top: 1em;
}

.mr-05 {
    margin-right: 0.5em;
}

.mb-05 {
    margin-bottom: 0.5em;
}

.mr-1 {
    margin-right: 1em;
}

.mr-2 {
    margin-right: 2em;
}

.mb-1 {
    margin-bottom: 1em;
}

.mb-2 {
    margin-bottom: 2em;
}

.mb-3 {
    margin-bottom: 3em;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.m-2 {
    margin: 2em;
}

.m-3 {
    margin: 3em;
}

.ml-3 {
    margin-left: 3em;
}

.ml-1 {
    margin-left: 1em;
}

.ml-2 {
    margin-left: 2em;
}

.mr-3 {
    margin-right: 3em;
}

.white-space-nowrap {
    white-space: nowrap;
}

.font-weight-600 {
    font-weight: 600;
}

.hrStyles {
    border: 1px solid rgba(33, 33, 33, 0.08);
}

.h-100vh {
    height: 100vh;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.h-100 {
    height: 100%;
}

.text-center {
    text-align: center;
}

::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #c3cad5;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #c3cad5;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(151, 146, 146);
}

.w-100 {
    width: 100%;
}

.text-decoration-none {
    text-decoration: none;
}

.overflow-hidden {
    overflow: hidden;
}
